import './scss/app.scss';
import React from 'react';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      inputText: null,
      isLoading: false,
      outputText: null,
      displayOutputTextIdx: 0,
    };
  }

  handleGenerateRhyme = () => {

    //var text = "\n\n this is some output! so let this break your mind!\nwhatever you think, whatever you mind";

    this.setState({
      isLoading: true,
      //outputText: text,
    });

    fetch("https://rhymegen.xyz/api.php", {
    //fetch("http://localhost:8080/api.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "text": this.state.inputText,
      }),
    })
    .then((response) => response.json())
    .then((data) => {

      this.setState({
        isLoading: false,
        outputText: data["text"],
        displayOutputTextIdx: 0,
      });

    })
    .catch((error) => {
      this.setState({
        isLoading: false,
        outputText: "Could not generate text. Try again.",
      });
      console.error("Error:", error);
    });
  }

  handleInputTextChange = (e) => {
    this.setState({
      inputText: e.target.value
    });
  }

  increaseDisplayOutputIdx = async () => {
    await new Promise(resolve => {
      setTimeout(() => {
        if(this.state.displayOutputTextIdx < this.state.outputText.length) {
          this.setState({
            displayOutputTextIdx: this.state.displayOutputTextIdx + 1
          });
        }
      }, 25);
    })
  }

  getOutputContent = () => {
    if(this.state.isLoading) {
      return (
        <p> is loading...</p>
      );
    }
    else if(this.state.outputText) {
      var outputTexts = this.state.outputText.replace("\n\n", "");
      outputTexts = outputTexts.slice(0, this.state.displayOutputTextIdx).split('\n');
      this.increaseDisplayOutputIdx();
      return (
        <div>
          <p className="generated-text">{outputTexts[0]}<br/>{outputTexts[1]}</p>
        </div>
      );
    }
    else {
      return (
        <p className="empty-state">No output generated yet. Type something in the input box above and click on "Generate" to create a new rhyme!</p>
      );
    }
  }

  render = () => {
      return (
        <div className="content-wrapper">
          <div className="content">

              <div className="header">
                <h1>rhymegen.xyz</h1>
                <h2>Very Intelligent Rhyme Generator</h2>
                <h3>Create unique rhymes as a rhyming couplet, powered by AI. Just type in your text below in the language of your choice.</h3>
              </div>

              <div className="rhymegpt">
                <p><s>Just start by typing below!</s></p>
                <p className="bold">Check out my custom GPT called <a href="https://chat.openai.com/g/g-F5biiksy7-rhymegpt">RhymeGPT</a>. It's free!</p>
              </div>

              <div className="input-wrapper">
                <input placeholder="chilling in the park, i'm so... " text={this.state.inputText} onChange={this.handleInputTextChange} disabled={true} />
                <button disabled={ this.state.inputText === null || this.state.inputText === "" } onClick={this.handleGenerateRhyme}>Generate</button>
              </div>

              <div className="output-wrapper">
                <h1>Generated Rhyme:</h1>
                {this.getOutputContent()}
              </div>

              {/* <div className="ads-wrapper">
                <div className="ads">
                  <p>ads...</p>
                </div>
              </div>*/}

          </div>
        </div>
      );
  }
}

export default App;
